// import QRCode from '@zxing/library/esm/core/qrcode/encoder/QRCode.js';
import {
  API_JWT_ACCESS_URL,
  API_JWT_VERIFY_URL,
  API_SSO_USER_URL,
  API_PACKAGES_URL,
  API_PACKAGESTATUS_URL,
  API_PACKAGEUSAGES_URL,
  API_CART_URL,
  API_CREATECART_URL,
  API_CHECKOUT_URL,
  API_CREATECHECKOUT_URL,
  API_CUSTOMER_URL,
  API_TREATMENT_URL,
  API_ITEM_URL,
  API_ITEMPURCHASE_URL,
  API_CATALOG_URL,
  API_USER_URL,
  API_DROPDOWNOPTION_URL,
  API_REFTYPE_URL,
  API_PAYMENT_REQUEST,
  API_PAYMENTFEE_URL,
  API_PAYMENT_URL,
  // API_PAYMENT_METHOD_SESSION,
  // API_PAYMENT_SESSION,
  // API_PAYMENT_DETAILS_SESSION,
  // API_PAYMENT_TERMINAL_SESSION,
} from './config.js';
import { AJAX } from './helpers.js';
import { AJAXFILE } from './helpers.js';
// import { AJAXPAY } from './helpers.js';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
// import {
//   AdyenCheckout,
//   Dropin,
//   Card,
//   GooglePay,
//   ApplePay,
//   WeChat,
// } from '@adyen/adyen-web';

//Initialize the required object data and group them under state object
export const state = {
  search: {
    query: '',
    results: [],
    chartresults: [],
    cameraresults: [],
    camerastate: [],
  },
  package: {
    editDbResults: [],
    editTableResults: [],
    selectedRowQuery: [],
    editPackageDetails: [],
    status: [],
    uploadedPackage: [],
    refTypes: [],
    refTypesName: [],
  },
  usage: {
    results: [],
    update: [],
  },
  cart: {
    userResults: [],
    status: [],
    update: [],
  },
  checkout: {
    results: [],
    update: [],
    uploadedCheckout: [],
    paymentMethod: [],
    paymentBrand: [],
    paymentStatus: [],
    subPayment: [],
    uploadedSubPayment: [],
    subPaymentPackage: [],
    billType: [],
    editDbResults: [],
    orderNumber: [],
  },
  payment: {
    uploadedPayment: [],
    results: [],
    paymentNumber: [],
  },
  customer: {
    matchResults: [],
    results: [],
    editDbResults: [],
  },
  treatment: {
    matchResults: [],
    results: [],
    editDbResults: [],
    treatmentNameList: [],
    treatmentCategory: [],
  },
  item: {
    results: [],
    itemCategory: [],
    itemSize: [],
    editDbResults: [],
    itemPurchase: [],
  },
  catalog: {
    results: [],
    location: [],
    unique: [],
  },
  user: {
    results: [],
    ssoResults: [],
  },
  chart: {
    monthlySales: [],
    sortedMonthlySales: [],
    monthlySalesData: {},
    customerSales: [],
    customerSalesData: [],
    monthlyBill: [],
    sortedMonthlyBill: [],
    monthlyBillData: {},
  },
};

/* FUNCTIONS */

// To generate unique Order Number. Can be used for other unique Number as well.
const addUniqueNumber = function () {
  const stringId = Date.now().toString();
  const randomId = Math.floor(Math.random() * 100);
  const dualDigits = randomId > 9 ? randomId.toString() : '0' + randomId; // add a "0" prefix if it is a single digit
  return stringId + dualDigits;
};

const titleCase = function (string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

/* ACCESS */

// To retrieve the JWT access and refresh token and save it in Local Storage
const loadToken = async function (loginDetails) {
  try {
    const method = 'POST';
    const data = await AJAX(
      `${API_JWT_ACCESS_URL}`,
      null,
      loginDetails,
      method
    );
    localStorage.setItem('accesstoken', JSON.stringify(data.access));
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To verify token validity
const verifyToken = async function (token, tokenJson) {
  try {
    const method = 'POST';
    const data = await AJAX(`${API_JWT_VERIFY_URL}`, token, tokenJson, method);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//Manage login with ID and password
export const login = async function (newLogin) {
  try {
    //convert to API package format
    const loginDetails = {
      email: newLogin.loginid,
      password: newLogin.loginpassword,
    };
    //GET access token
    await loadToken(loginDetails);
    const token = localStorage.getItem('accesstoken');
    if (token !== null) {
      window.location.href = './main.html';
      localStorage.setItem('username', JSON.stringify(newLogin.loginid));
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//Check login status. Log out if token is not valid or verified
export const isLoggedin = async function () {
  try {
    const token = localStorage.getItem('accesstoken');
    if (token === null) {
      window.location.href = './index.html';
    }
    if (token !== null) {
      const tokenJson = JSON.parse(`{"token":${token}}`);
      await verifyToken(token, tokenJson);
      //Retreive the user name from local storage and remove the double quote.
      // const userName = localStorage.getItem('username').slice(1, -1);
    }
  } catch (err) {
    console.error(err);
    logout(); //redirect to login page and remove the expired access token. To troubleshoot immediate logout after login, blanko this line!
  }
};

//Control loading of standard menu based on user profile
export const loadMenu = async function () {
  try {
    // Load user and organization details to landing page
    await loadSSOUserSearchResults();
    await loadUserResults();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//Control loading of menu for each user based on user permissions
export const loadAccessMenu = async function () {
  try {
    await loadItemSearchResults('#'); // to check user GET ITEM permissions. Throw err to maincontroller if error (403 Forbidden error)
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//Log out user
export const logout = async function () {
  try {
    // Remove the token and redirect to login page
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('username');
    window.location.href = './index.html';
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/* LOAD SEARCH RESULTS*/

// To retrieve the login user and organization details from tscms domain. This is needed as logo image cannot be retrieved cross domain by jsPdf > html2canvas
export const loadUserResults = async function (query) {
  try {
    const searchQuery = '#';
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);

    //GET user API using search query and access token
    const data = await AJAX(`${API_USER_URL}?search=${searchQuery}`, token);
    // console.log(data);

    // To retrieve the logo from the org, if null get from the parent org
    const orgLogo =
      data.organization.logo === null && data.organization.parent_org
        ? data.organization.parent_org.logo
        : data.organization.logo;

    state.user.results = data; // data.map is not needed as only one result will be returned and it returns as an Object instead of Array of Objects.
    state.user.results.orgLogo = orgLogo;
    // console.log(state.user.results);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To retrieve the login user and organization details from sso domain
//!! LOGIN USER MUST HAVE AN ORGANIZATION LINKED TO IT IN SSO, ELSE ORG RELATED DATA CANNOT BE RETRIEVED AND IT WILL IMMEDIATELY LOGOUT
export const loadSSOUserSearchResults = async function (query) {
  try {
    // state.search.query = query;
    const query = '#';
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET user API using search query and access token. Note that this API will only return one owner result due to settings set in Django
    const data = await AJAX(`${API_SSO_USER_URL}?search=${query}`, token);

    // console.log(data);

    //To form the user name initial to be populated to the nav bar
    const initial =
      data.first_name.slice(0, 1).toUpperCase() +
      data.last_name.slice(0, 1).toUpperCase();

    // To retrieve the logo from the org, if null get from the parent org
    const orgLogo =
      data.organization.logo === null && data.organization.parent_org
        ? data.organization.parent_org.logo
        : data.organization.logo;
    // To retrieve the registration id from the org, if null get from the parent org
    const orgRegID =
      data.organization.registration_id === null && data.organization.parent_org
        ? data.organization.parent_org.registration_id
        : data.organization.registration_id;

    // To retrieve the tax value from the org, if null get from the parent org
    const orgTaxID =
      data.organization.tax_id === null && data.organization.parent_org
        ? data.organization.parent_org.tax_id
        : data.organization.tax_id;

    // To retrieve the tax value from the org, if null get from the parent org
    const orgTaxValue =
      data.organization.tax_value === null && data.organization.parent_org
        ? data.organization.parent_org.tax_value
        : data.organization.tax_value;

    state.user.ssoResults = data; // data.map is not needed as only one result will be returned and it returns as an Object instead of Array of Objects.
    state.user.ssoResults.initial = initial;
    state.user.ssoResults.orgLogo = orgLogo;
    state.user.ssoResults.orgRegID = orgRegID;
    state.user.ssoResults.orgTaxID = orgTaxID;
    state.user.ssoResults.orgTaxValue = orgTaxValue;

    // console.log(state.user.ssoResults);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To convert package objects array from database to formats suitable to be populated to tables
const createPackageObject = function (data) {
  // console.log(data);
  return data.map(pkg => {
    //Change the object names and bring treatment name to the first array level via array map if there is only <= 1 treatment
    const [treatName] =
      pkg.packagedetails.length <= 1
        ? pkg.packagedetails.map(det => det.treatment_name)
        : 'M';

    // To create an object variable based on packagereferences array for loop as tabulator can only read direct from object but not array
    // Need to include the check for reftype_order !== null because some packages may have references that have no display_order configured.

    let packageRefValue = {};
    for (
      let i = 1;
      i <= pkg.packagereferences.length &&
      pkg.packagereferences[i - 1].reftype_order !== null;
      i++
    ) {
      packageRefValue[pkg.packagereferences[i - 1].reftype_order] =
        pkg.packagereferences[i - 1].ref_value;
    }

    return {
      id: pkg.package_id,
      custID: pkg.customer,
      custName: pkg.customer_nickName,
      custMobile: pkg.customer_mobile,
      custEmail: pkg.customer_email,
      custBirthday: pkg.customer_dateOfBirth,
      treatmentName: treatName,
      dateBought: pkg.date_bought,
      packageDetails: pkg.packagedetails,
      qtyBought: pkg.qty_bought,
      qtyUsed: pkg.qty_used,
      qtyBalance: pkg.qty_balance,
      priceBought: pkg.price_bought,
      totalPrice: pkg.total_price,
      priceBoughtwTax: pkg.price_bought_tax,
      totalPricewTax: pkg.total_price_tax,
      paymentBalance: pkg.payment_balance,
      subPayment: pkg.subsequent_payment,
      priceUsed: pkg.price_used,
      priceBalance: pkg.price_balance,
      branch: pkg.branch,
      status: pkg.package_status,
      active: pkg.active,
      packageRef: pkg.packagereferences,
      packageRefValue: packageRefValue,
    };
  });
};

// To load the package search results
export const loadPackagesSearchResults = async function (
  query,
  filterFromDate,
  filterToDate
) {
  try {
    // to retrieve the package status id from database
    const statusQuery = 'Package Status';
    await loadPackageStatus(statusQuery);

    // find the package status Pending id
    const packagePendingStatusId = state.package.status.find(
      x => x.status === 'Pending'
    ).id;

    state.search.query = query;
    //GET access token
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET packages API using search query and access token
    const data = await AJAX(
      `${API_PACKAGES_URL}?from_bought_date=${filterFromDate}&to_bought_date=${filterToDate}&search=${query}`,
      token
    );

    //Call the function to convert the Objects format to populate to tables, and filter out packages with status = Pending which is for Cart only
    state.search.results = createPackageObject(
      data.filter(pkg => pkg.status !== packagePendingStatusId)
    );

    // console.log(state.search.results);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load all the Package Usage results if no record selected, or load the relevant ones based on selected Package row
export const loadPackagesUsageResults = async function (
  query,
  filterFromDate,
  filterToDate
) {
  try {
    // //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    // //GET package usage API using the query and access token. If the search is #, the following query will be truncated, thus search is placed behind the dates.
    const data = await AJAX(
      `${API_PACKAGEUSAGES_URL}?from_date_used=${filterFromDate}&to_date_used=${filterToDate}&search=${query}`,
      token
    );

    //To convert Objects format to populate to tables
    state.usage.results = data.map(pkg => {
      // const dateUsed = pkg.insert_date.toLocaleString().slice(0, 10); //Convert date based on local timezone and slice to contain only the yyyy-MM-dd
      return {
        id: pkg.package_usage_id,
        custName: pkg.customer_nickName,
        packageId: pkg.package,
        treatmentName: pkg.package_treatment,
        dateUsed: pkg.date_used,
        qtyUsed: pkg.qty_consumed,
        priceUsed: pkg.price_consumed,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the Cart search results
export const loadCartSearchResults = async function (query) {
  try {
    // Retrieve the user id in order to get user's cart items and retrieve user > company tax value for total checkout amount calculation
    const user = state.user.results.id;
    const userEmail = state.user.results.email; // to populate to cart and then to payment

    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET packages API using search query and access token. Note that query is set in mainController (e.g set to Pending for Cart initial load).
    //Search is icontains user id and Pending to make sure that only relevant cart items are returned and loaded
    const data = await AJAX(`${API_CART_URL}?search=${user}+${query}`, token);
    // To find the catalog Status 'Pending' id
    const catalogPendingStatusId = state.cart.status.find(
      stat => stat.status === 'Pending'
    ).id;

    // console.log(data);

    // to retrieve all the carts that belong to the user only AND status = Pending
    state.cart.userResults = data
      .filter(cart => {
        return (
          cart.user === user && cart.catalog_status === catalogPendingStatusId
        );
      })
      .map(cat => {
        // to assign different values for both catalog and package
        let objectId =
          cat.package === null
            ? cat.catalog.catalog_id
            : cat.package.package_id;
        let packageId = cat.package !== null ? cat.package.package_id : null;
        let itemId = cat.package === null ? cat.catalog.item.item_id : null;
        let objectName =
          cat.package === null
            ? cat.catalog.item.item_name
            : 'Package: ' +
              cat.package.packagedetails[0].treatment_name +
              '...';
        // To find the main pic among the multiple images of an item. If the item has no picture, set to null. Note that every item must have at least 1 picture uploaded.
        let objectImage =
          cat.package === null
            ? cat.catalog.item.item_main_image
            : cat.package.packagedetails[0].treatment_main_image;
        let objectPrice =
          cat.package === null
            ? cat.catalog.selling_price
            : cat.package.price_bought;
        let objectTotalPrice =
          cat.package === null ? null : cat.package.total_price;
        let objectAvailQty =
          cat.package === null
            ? cat.catalog.inventory_available_qty
            : undefined;
        let objectOverrideAvailQty =
          cat.package === null ? cat.catalog.override_available_qty : null;

        let objectSizeName =
          cat.package === null && cat.catalog.item.item_size !== null
            ? cat.catalog.item.item_sizeName
            : null;

        return {
          id: cat.cart_id,
          catalogId: objectId,
          packageId: packageId,
          itemId: itemId,
          itemName: objectName,
          itemImage: objectImage,
          itemSizeName: objectSizeName,
          sellingPrice: objectPrice,
          totalPrice: objectTotalPrice,
          invAvailQty: objectAvailQty,
          overrideAvailQty: objectOverrideAvailQty, // allow user to override the inventory available qty
          catalogStatus: cat.catalog_status,
          catalog: cat.catalog,
          package: cat.package,
          userEmail: userEmail,
          cartActive: cat.active,
        };
      });
    // console.log(state.cart.userResults);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the checkout search results
export const loadCheckoutSearchResults = async function (
  query,
  filterFromDate,
  filterToDate
) {
  try {
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);

    // Call SSO User API to retrieve the login user's organization details to be displayed on the bill
    await loadSSOUserSearchResults();
    // Call tscms User API to retrieve the login user's org logo as cross domain for jsPdf is not allowed
    await loadUserResults();
    //GET packages API using search query and access token
    const data = await AJAX(
      `${API_CHECKOUT_URL}?from_bill_date=${filterFromDate}&to_bill_date=${filterToDate}&search=${query}`,
      token
    );

    // to add a new itemPrice field to the checkoutdetails so that it can be used in checkoutView. Retrieve package total price if item_price field is not available else use item_price
    state.checkout.results = data.map(bill => {
      bill.checkoutdetails.map(line => {
        line.itemQty = line.item ? line.item_qty : line.package_qtyBought;
        line.itemName = line.item ? line.item_name : line.package_name;

        if (line.package === null) {
          line.itemLineId = `I${line.item}`;
        } else if (line.item === null) {
          line.itemLineId = `P${line.package.package_id}`;
        }

        line.itemTotalPrice = line.item_price
          ? line.item_price
          : line.package_totalPrice;

        // to add a new itemPricePaid field to the checkoutdetails so that it can be used in checkoutView. If item price exists, use it else use package pricePaid.
        line.itemPricePaid = line.item_price
          ? line.item_price
          : line.package_pricePaid;

        line.itemTotalPriceTax = line.item_price
          ? line.item_priceTax
          : line.package_totalPriceTax;

        // to add a new itemPricePaid field to the checkoutdetails so that it can be used in checkoutView. If item price exists, use it else use package pricePaid.
        line.itemPricePaidTax = line.item_price
          ? line.item_priceTax
          : line.package_pricePaidTax;

        // to add a new itemSubPayment field to the checkoutdetails so that it can be used in checkoutView. If exists, use it else use set to 0
        line.itemSubPayment = line.package_subPayment
          ? line.package_subPayment
          : 0;

        // to add a new itemPaymentBalance field to the checkoutdetails so that it can be used in checkoutView. If exists, use it else use set to 0
        line.itemPaymentBalance = line.package_paymentBalance
          ? line.package_paymentBalance
          : 0;
      });

      // to calculate the payment balance and add it as a field in Bill so that it can be used in checkoutView. If it is a Bill, show the difference bet total price and price paid
      // summed from the lines, else if it is Receipt, just use the payment_balance from the database.

      const totalPrice = bill.checkoutdetails
        .reduce((acc, cur) => acc + cur.itemTotalPrice, 0)
        .toFixed(2);

      const sumPricePaid = bill.checkoutdetails
        .reduce((acc, cur) => acc + cur.itemPricePaid, 0)
        .toFixed(2);

      const totalPriceTax = bill.checkoutdetails
        .reduce((acc, cur) => acc + cur.itemTotalPriceTax, 0)
        .toFixed(2);

      const sumPricePaidTax = bill.checkoutdetails
        .reduce((acc, cur) => acc + cur.itemPricePaidTax, 0)
        .toFixed(2);

      const sumBalance =
        bill.billType_name === 'Bill'
          ? (+totalPriceTax - +sumPricePaidTax).toFixed(2)
          : bill.payment_balance;

      const billPaid =
        bill.billType_name === 'Bill' ? sumPricePaid : bill.subsequent_payment;

      return {
        id: bill.checkout_id,
        orderNumber: bill.order_number,
        billDate: bill.bill_date,
        custID: bill.customer,
        custName: bill.customer_nickName,
        custMobile: bill.customer_mobile,
        totalPrice: totalPrice,
        sumPricePaid: sumPricePaid,
        totalPriceTax: totalPriceTax,
        sumPricePaidTax: sumPricePaidTax,
        billBalance: sumBalance,
        billSubpayment: bill.subsequent_payment,
        billPaid: billPaid,
        paymentMethod: bill.paymentMethod_name,
        paymentStatus: bill.paymentStatus_name,
        transactStatus: bill.transact_status,
        billType: bill.billType_name,
        billActive: bill.active,
        checkoutDetails: bill.checkoutdetails,
        orgName: state.user.ssoResults.organization.name,
        orgEmail: state.user.ssoResults.organization.email,
        orgPhone: state.user.ssoResults.organization.mobile,
        orgLogo: state.user.results.orgLogo,
        orgRegID: state.user.ssoResults.orgRegID,
        orgTaxID: state.user.ssoResults.orgTaxID,
        orgTaxValue: state.user.ssoResults.orgTaxValue,
        orgAddress1: state.user.ssoResults.organization.address,
        orgAddress2:
          state.user.ssoResults.organization.unit_no +
          ', ' +
          state.user.ssoResults.organization.postcode +
          ', ' +
          state.user.ssoResults.organization.country,
      };
    });
    // console.log(state.checkout.results);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To create the Hitpay payment request
export const loadPaymentSearchResults = async function (
  query,
  filterFromDate,
  filterToDate
) {
  try {
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);

    const data = await AJAX(
      `${API_PAYMENT_URL}?from_payment_date=${filterFromDate}&to_payment_date=${filterToDate}&search=${query}`,
      token
    );

    state.payment.results = data.map(pay => {
      const paymentBrandName =
        pay.payment_brand === null ? null : pay.paymentBrand_name;
      return {
        id: pay.payment_id,
        paymentNumber: pay.payment_number,
        paymentDate: pay.payment_date,
        custName: pay.name,
        custMobile: pay.mobile,
        checkout: pay.checkout,
        paymentMethod: pay.payment_method,
        paymentBrand: pay.payment_brand,
        paymentMethodName: pay.paymentMethod_name,
        paymentBrandName: paymentBrandName,
        currency: pay.currency,
        fixedFee: pay.fixed_fee,
        discountFee: pay.discount_fee,
        totalFee: pay.total_fee,
        totalAmt: pay.total_amount,
        amount: pay.amount,
        netAmt: pay.net_amount,
        discountFeeRate: pay.discount_fee_rate,
        transactStatus: pay.transact_status,
        transactBrand: pay.transact_brand,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the customer search results + customer field autocomplete
export const loadCustomersSearchResults = async function (
  query,
  selectedQuery
) {
  try {
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);

    //GET packages API using search query and access token. If selectQuery (from PackageCustomerMatchList) is not undefined, search only based on selectedQuery which gives better performance
    const data =
      selectedQuery === undefined
        ? await AJAX(`${API_CUSTOMER_URL}?search=${query}`, token)
        : await AJAX(`${API_CUSTOMER_URL}?search=${selectedQuery}`, token);
    // if selectedQuery is not null, execute the RegExp else set the array to []
    state.customer.matchResults = !selectedQuery
      ? []
      : data
          .filter(cust => {
            const regex = new RegExp(`^${selectedQuery}`, 'gi'); //use RegExp to match the startwith of query and gi to ignore case
            return cust.nick_name.match(regex); // return customer nick name
          })
          .map(cust => {
            return {
              id: cust.customer_id,
              custName: cust.nick_name,
              matchlistName: cust.nick_name,
            };
          });

    // if (!selectedQuery) state.customer.matchResults = [];
    //Call the function to convert the Objects format to populate to tables
    state.customer.results = data.map(cust => {
      const createdDate = cust.insert_date.toLocaleString().slice(0, 10);
      return {
        id: cust.customer_id,
        custFirstName: cust.first_name,
        custLastName: cust.last_name,
        custNickName: cust.nick_name,
        custGender: cust.gender,
        custMobile: cust.mobile,
        custBirthday: cust.date_of_birth,
        custEmail: cust.email,
        custAddress: cust.address,
        custUnitNo: cust.unit_no,
        custCountry: cust.country,
        custPostcode: cust.postcode,
        custActive: cust.active,
        custInsertDate: createdDate,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the treatment search results + treatment field autocomplete
export const loadTreatmentSearchResults = async function (
  query,
  selectedQuery
) {
  try {
    // const query = '#';
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET packages API using search query and access token
    const data =
      selectedQuery === undefined
        ? await AJAX(`${API_TREATMENT_URL}?search=${query}`, token)
        : await AJAX(`${API_TREATMENT_URL}?search=${selectedQuery}`, token);
    //To retrieve the list of treatment name list from database and populate to package table dataset for treatment name edit
    state.treatment.treatmentNameList = data.map(treatlist => {
      return treatlist.treatment_name;
    });
    //To retrieve the list of treatment name list from database based on user entry to fulfil the new package treatment autocomplete feature
    state.treatment.matchResults = !selectedQuery
      ? []
      : data
          .filter(treat => {
            const regex = new RegExp(`^${selectedQuery}`, 'gi'); //use RegExp to match the startwith of query and gi to ignore case
            return treat.treatment_name.match(regex);
          })
          .map(treat => {
            return {
              id: treat.treatment_id,
              treatmentName: treat.treatment_name,
              matchlistName: treat.treatment_name, //added so that a common method can be used in View for all auto-complete fields
            };
          });
    // if (!selectedQuery) state.treatment.matchResults = [];
    //To retrieve all the treatment search results from database
    state.treatment.results = data.map(treat => {
      return {
        id: treat.treatment_id,
        treatName: treat.treatment_name,
        treatCategory: treat.treatment_categoryName,
        treatmentMainImage: treat.treatment_main_image,
        treatActive: treat.active,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the Items search results
export const loadItemSearchResults = async function (query) {
  try {
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET packages API using search query and access token
    const data = await AJAX(`${API_ITEM_URL}?search=${query}`, token);

    //To retrieve all the item search results from database
    state.item.results = data.map(item => {
      let itemImagesSrc = item.itemimages.map(img => {
        return img.item_image; // always remember to put a "return" key word for it to work
      });
      return {
        id: item.item_id,
        skuNumber: item.sku_number,
        itemName: item.item_name,
        itemDescription: item.item_description,
        itemMainImage: item.item_main_image,
        itemQrCode: item.item_qr_code,
        itemImagesSrc: itemImagesSrc,
        itemImages: item.itemimages,
        itemCode: item.item_code,
        itemSize: item.item_sizeName,
        itemColor: item.item_color,
        unitPrice: item.unit_price,
        itemCategory: item.item_categoryName,
        itemRep: item.item_rep,
        itemActive: item.active,
      };
    });
    // console.log(state.item.results);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the Catalog search results
export const loadCatalogSearchResults = async function (
  query,
  filterFromDate,
  filterToDate
) {
  try {
    const userBranch = state.user.results.organization.branch_name;
    // const query = '#';
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET packages API using search query and access token
    const data = await AJAX(
      `${API_CATALOG_URL}?from_list_date=${filterFromDate}&to_list_date=${filterToDate}&search=${query}`,
      token
    );

    // to retrieve all the catalogs that belong to the user branch & those catalog with no inventory tracking only (if no inventory tracking it means it is regardless of branch)
    state.catalog.location = data
      .filter(cat => {
        return (
          (cat.inventory_location === userBranch && cat.active === 'Y') ||
          (cat.inventory_location === undefined && cat.active === 'Y')
        );
      })
      .map(cat => {
        // to find the url image src of the Main Pic and bring it forward as an object element. Note that array.find will only return the first result so it will still work if there are more than 1 result
        // let itemMainImage = cat.item.itemimages.find(
        //   img => img.main_pic === 'Y'
        // )
        //   ? cat.item.itemimages.find(img => img.main_pic === 'Y').item_image
        //   : null;
        return {
          id: cat.catalog_id,
          invLocation: cat.inventory_location,
          invAvailQty: cat.inventory_available_qty,
          overrideAvailQty: cat.override_available_qty, // allow user to override the inventory available qty
          originalPrice: cat.original_price,
          sellingPrice: cat.selling_price,
          listDate: cat.list_date,
          discount: cat.discount,
          itemMainImage: cat.item.item_main_image,
          item: cat.item,
          catalogActive: cat.active,
        };
      });

    // to find the unique catalogs based on item code. This is to consolidate same item with different sizes into one catalog only
    state.catalog.unique = state.catalog.location.reduce((acc, obj) => {
      if (
        !acc.some(o => o.item.item_code === obj.item.item_code) &&
        obj.item.item_rep === 'Y'
      ) {
        acc.push(obj);
      }
      return acc;
    }, []);

    //To retrieve all the catalog search results from database, regardless of branch. Not use yet.
    state.catalog.results = data.map(cat => {
      return {
        id: cat.catalog_id,
        invLocation: cat.inventory_location,
        invAvailQty: cat.inventory_available_qty,
        overrideAvailQty: cat.override_available_qty, // allow user to override the inventory available qty
        originalPrice: cat.original_price,
        sellingPrice: cat.selling_price,
        listDate: cat.list_date,
        discount: cat.discount,
        item: cat.item,
        catalogActive: cat.active,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/* ZXING SCAN FUNCTION */

export const loadZxingScanItemResults = async function (scanType) {
  try {
    const videoElement = document.querySelector('.popup-camera-video');
    // Initialize the ZXing code reader
    const codeReader = new BrowserMultiFormatReader();

    // Get the video devices using enumerateDevices() instead of getVideoInputDevices()
    const videoDevices = await zxingGetVideoDevices();

    const firstDeviceId =
      videoDevices && videoDevices.length > 0 ? videoDevices[0].deviceId : ''; // Select the first camera

    // Request access to the camera. It will result in a prompt to the user to allow camera access
    const stream = await zxingGetVideo();

    // Set to display video stream in element and set video properties
    // // videoElement.autoplay = true; // set to autoplay so that it can livestream the video immediately
    // // videoElement.playsInline = true; // set to playsinline so that it can livestream the video immediately
    videoElement.srcObject = stream;

    const scanJson = await zxingDecodeBarcode(
      codeReader,
      firstDeviceId,
      videoElement
    );

    // console.log(scanJson);
    // if it is itemscan Search, retrieve only the itemcode from the JSON, else retrieve the sku number
    state.search.cameraresults =
      scanType === 'once' ? scanJson.itemcode : scanJson.skunumber;

    // Stop the video and scanner after scan
    await zxingStopVideo(videoElement); // stop the camera every time it is scanned to avoid multiple camera element staying open

    state.search.camerastate = true; // set camerastate to true so that it can continue to loop to scan items in controlZxingScanItemCheckout. The final state will be set to false when user clicks on X to close the camera pop-up. This must be after zxingStopVideo
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// function to get full list of available media devices
export const zxingGetVideoDevices = async function () {
  try {
    // Get the list of available media devices (audio, video, etc.)
    const devices = await navigator.mediaDevices.enumerateDevices();

    // Filter to get only video input devices (cameras)
    const videoDevices = devices.filter(device => device.kind === 'videoinput');

    if (videoDevices.length > 0) {
      // console.log('Video devices:', videoDevices);
      return videoDevices; // Return the list of video devices
    } else {
      console.error('No video input devices found');
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// function to access and stream the video
export const zxingGetVideo = async function () {
  try {
    // Request access to the camera
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    return stream;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// // Check if input is a valid JSON format
// const isValidJSON = function (str) {
//   try {
//     JSON.parse(str); // Try to parse the string
//     return true; // Return true if it's valid JSON
//   } catch (e) {
//     return false; // Return false if parsing fails
//   }
// };

// function to access and stream the video
export const zxingDecodeBarcode = async function (
  codeReader,
  firstDeviceId,
  videoElement
) {
  try {
    // Decode the barcode scanned from the video stream. As decodeFromVideoDevice is not an async function, it is enclosed in Promise to ensure that it is async and decode result is
    // obtained and return back before it continues its downstream execution in mainContoller.js. NotFoundException has to be excluded as it is a continuous stream scanning and will
    // result in continuous error before the barcode is brought into the camera view
    return new Promise((resolve, reject) => {
      codeReader.decodeFromVideoDevice(
        firstDeviceId,
        videoElement,
        (result, error) => {
          if (result) {
            const decodedText = result.text;
            // Check if the scan result is a proper Json, if yes, parse the json and return, else just return the exact text
            try {
              JSON.parse(decodedText);
              resolve(JSON.parse(decodedText));
            } catch (e) {
              resolve(decodedText);
            }
          } else if (error && !NotFoundException) {
            console.log(error);
            reject(error);
          } else {
            setTimeout(function () {
              //return reject if no scan is performed after 10 secs
              reject('No Scan Detected');
            }, 10000);
          }
        }
      );
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// function to stop the video. It will have some delay of about 10 secs
export const zxingStopVideo = async function (videoElement) {
  try {
    state.search.camerastate = false; // set camerastate to false so that it will stop to loop to scan items in controlZxingScanItemCheckout
    // Initialize the ZXing code reader
    const codeReader = new BrowserMultiFormatReader();

    // Stop all video tracks
    let stream = videoElement.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
    }

    // Reset the video source and ZXing reader
    videoElement.srcObject = null;
    videoElement.pause();
    // Force video element reload to reset completely
    videoElement.load();
    // Reset the ZXing reader
    codeReader.reset();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/* UPDATE*/

//To find the treatment id of the updated packages in order to update to database
export const findTreatmentId = async function (treatment, qtyUsage) {
  try {
    const query = '#';
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET packages API using search query and access token
    const data = await AJAX(`${API_TREATMENT_URL}?search=${query}`, token);
    const [treatData] = data //As data is an array, we need to use [treatData] to return the object within the array instead of returning the array itself
      .filter(treat => {
        const regex = new RegExp(`^${treatment}`, 'gi'); //use RegExp to match the startwith of query and gi to ignore case
        return treat.treatment_name.match(regex);
      })
      .map(treat => {
        return {
          treatment: treat.treatment_id,
          treatment_name: treat.treatment_name,
          qty_usage: qtyUsage, //to return the edited qty usage
        };
      });
    return treatData;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To update the edit of the Packages to the database, save the changes to state.package.editTableResults variable, and render it back to the table for real-time update
export const updatePackages = async function (editedPackages) {
  try {
    const taxRate = +state.user.ssoResults.orgTaxValue / 100;
    // to retrieve the package status id from database
    // const query = 'Package Status';
    // await loadPackageStatus(query);

    //Promise.all is required to perform an async call to another async function within Array.map
    //To allow for subTable Treatment Name changes, need to do a double await call to findTreatmentId by accessing the packageDetails array within the package array
    state.package.editDbResults = await Promise.all(
      editedPackages.map(async pkg => {
        state.package.editPackageDetails = await Promise.all(
          pkg.packageDetails.map(async treat =>
            findTreatmentId(treat.treatment_name, treat.qty_usage)
          )
        );

        //calculate Balance qty based on updated Used qty
        pkg.qtyBalance = pkg.qtyBought - pkg.qtyUsed;
        // Calculate Used price and Balance price based on Used qty and convert to 2 decimal places
        pkg.priceUsed = (
          (pkg.priceBought * pkg.qtyUsed) /
          pkg.qtyBought
        ).toFixed(2);
        pkg.priceBalance = (pkg.priceBought - pkg.priceUsed).toFixed(2);

        pkg.priceBoughtwTax = +(pkg.priceBought * (1 + taxRate)).toFixed(2);
        pkg.totalPricewTax = +(pkg.totalPrice * (1 + taxRate)).toFixed(2);

        // Calculate payment balance based on total price and price bought if subPayment is 0, else it means subsequent payment has started thus will just deduct from the payment balance
        pkg.paymentBalance =
          pkg.subPayment > 0
            ? (pkg.paymentBalance - pkg.subPayment).toFixed(2)
            : (pkg.totalPricewTax - pkg.priceBoughtwTax).toFixed(2);

        //Change the package status based on check on qty usage. Note that if a new status is added, this is the only place that requires update.
        if (pkg.qtyUsed < pkg.qtyBought && pkg.qtyUsed !== 0)
          pkg.status = state.package.status.find(x => x.status === 'WIP');
        if (pkg.qtyUsed === pkg.qtyBought)
          pkg.status = state.package.status.find(x => x.status === 'Complete');
        if (pkg.qtyUsed === 0)
          pkg.status = state.package.status.find(x => x.status === 'New');

        // Below is to handle amendments to package reference values
        // packagereferences will continue to exist in the object after being added after package search. Below serve to replace the package reference values with the edited values
        // Using for loop to iterate through user entry package ref values, assign existing package ref to amended ref values, if not existing, insert the package ref object into the array

        for (let i = 1; i <= Object.keys(pkg.packageRefValue).length; i++) {
          let packageRefValue = 'pkg.packageRefValue.P' + i; // to form pkg.packageRefValue1....Value2 etc
          let packageRefFindValue = pkg.packageRef.find(
            // to find the array in packageRef which has the same reftype_order
            ref => ref.reftype_order === `P${i}`
          );
          let refOrder = Object.keys(pkg.packageRefValue)[i - 1]; // convert the object keys of packageRefValue to array so that its value can be obtained via for loop

          let refValue = Object.values(pkg.packageRefValue)[i - 1]; // convert the object values of packageRefValue to array so that its value can be obtained via for loop

          let refTypeId = state.package.refTypesName.find(
            // to find within retrieved reftype API where the packageRefValue Key (e.g P1) = reftype display_order
            ref => ref.display_order === refOrder
          ).reftype_id;

          if (packageRefFindValue === undefined) {
            // where the reftype is not existing in the original package, insert it, else if exist, change the refnum value to be the amended one
            pkg.packageRef.push({
              reftype: refTypeId,
              ref_value: refValue,
              reftype_order: refOrder,
            });
          } else {
            packageRefFindValue.ref_value = eval(packageRefValue);
          } // eval() helps to convert string to variable
        }

        // Have to keep below fields consistent with the PackageObject
        return {
          package_id: pkg.id,
          customer: pkg.custID,
          customer_nickName: pkg.custName,
          customer_mobile: pkg.custMobile,
          customer_dateOfBirth: pkg.custBirthday,
          date_bought: pkg.dateBought,
          packagedetails: state.package.editPackageDetails,
          packagereferences: pkg.packageRef,
          qty_bought: pkg.qtyBought,
          qty_used: pkg.qtyUsed,
          qty_balance: pkg.qtyBalance,
          total_price: pkg.totalPrice,
          price_bought: pkg.priceBought,
          total_price_tax: pkg.totalPricewTax,
          price_bought_tax: pkg.priceBoughtwTax,
          payment_balance: pkg.paymentBalance,
          subsequent_payment: pkg.subPayment,
          price_used: pkg.priceUsed,
          price_balance: pkg.priceBalance,
          branch: pkg.branch,
          status: pkg.status.id,
          package_status: pkg.status.status, // have to use name instead of id for update as tabulator updateData will update based on actual data instead of foreign key.
          active: pkg.active,
        };
      })
    );

    // console.log(state.package.editDbResults);

    //Call AJAX to update the edit to database using PUT method. Access token is retrieved from local Storage.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'PUT';
    const editDetails = state.package.editDbResults;
    for (let el of editDetails) {
      let data = await AJAX(
        `${API_PACKAGES_URL}${el.package_id}/`,
        token,
        el,
        method
      );

      //convert the Objects from database formats back to table formats and save it to state.edit.editTableResults variable. This variable will be used by controller to update back to packageView tables.
      state.package.editTableResults = createPackageObject(editDetails);
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To update Package statuses. This is used when click on Cart Manual Pay to change the Package status from Pending to New
export const updatePackageStatus = async function (packages) {
  try {
    // to retrieve the package status id from database
    const query = 'Package Status';
    await loadPackageStatus(query);
    const packageNewStatusId = state.package.status.find(
      x => x.status === 'New'
    ).id;
    const packagePurchase = Object.entries(packages) //convert Object to Array
      .filter(cart => cart[0].startsWith('packagepurchase')) // to pick out any array with first element starting with ctreatment, and which has a treatment id in the second element e.g  ['ctreatment2', '128,1']
      .map(cart => {
        const packagePurchaseArr = cart[1].split(',').map(el => el.trim());
        const [package_id] = packagePurchaseArr;
        return { package_id: +package_id, status: +packageNewStatusId };
      });

    //Call AJAX to update the edit to database using PUT method. Access token is retrieved from local Storage.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'PUT';
    // const editDetails = state.package.editDbResults;
    for (let el of packagePurchase) {
      let data = await AJAX(
        `${API_PACKAGESTATUS_URL}${el.package_id}/`,
        token,
        el,
        method
      );
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To insert the Package consumption (Package Usage table) based on qty consumed and package id
export const updatePackageUsage = async function (editedPackages) {
  try {
    // Convert to formats suitable to be populated to database
    state.usage.update = editedPackages
      .filter(pkg => pkg.qtyConsumed) //To filter out only those objects with updated Used Qty
      .map(pkg => {
        const priceConsumed = (
          (pkg.priceBought * pkg.qtyConsumed) /
          pkg.qtyBought
        ).toFixed(2);
        //Map to return only 2 properties needed to update Package Usage table
        return {
          package: pkg.id,
          qty_consumed: pkg.qtyConsumed,
          price_consumed: priceConsumed,
        };
      });

    //Call AJAX to insert records to PackageUsages table in database using POST method. Access token is retrieved from local Storage.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'POST';
    const editDetails = state.usage.update;
    const data = await AJAX(
      `${API_PACKAGEUSAGES_URL}`,
      token,
      editDetails,
      method
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To update the Cart catalog status once the catalog is removed or paid
export const updateCart = async function (editedCart) {
  try {
    // For Cart Manual Pay. find the status id of "Paid"
    const catalog_status_paid = state.cart.status.find(
      sta => sta.status === 'Paid'
    ).id;

    // For Cart Manual Pay. Filter the cart items that are paid and make sure this is applied only to Cart > Manual Pay
    const cartCheckout =
      'paymentemail' in editedCart
        ? Object.entries(editedCart) //convert Object to Array
            .filter(cart => cart[0].startsWith('cartid')) // to pick out any array with first element starting with ctreatment, and which has a treatment id in the second element e.g  ['ctreatment2', '128,1']
            .map(cart => {
              return {
                cart_id: +cart[1],
                user: state.user.results.id,
                catalog_status: catalog_status_paid,
              };
            })
        : '';

    // For Cart Delete (change status to Remove). Find the status id of "Remove"
    const catalog_status = editedCart.status
      ? state.cart.status.find(sta => sta.status === editedCart.status).id
      : null;

    //For Cart Delete and Manual Pay. Convert to API package format
    const editCart =
      'paymentemail' in editedCart
        ? cartCheckout
        : {
            cart_id: editedCart.cartId,
            user: state.user.results.id,
            catalog_status: catalog_status,
          };

    // If editCart has only 1 object, it is for Cart Delete and its length will be undefined as it is not an array. Else it will be for Manual Pay. Update the cart status by calling sendJSON to put it via API call. Will need a loop for Manual Pay as it has multiple items
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'PUT';
    if (editCart.length === undefined) {
      const uploaddata = await AJAX(
        `${API_CREATECART_URL}${editCart.cart_id}/`,
        token,
        editCart,
        method
      );
      // for Cart Manual Pay
    } else {
      for (let el of editCart) {
        await AJAX(`${API_CREATECART_URL}${el.cart_id}/`, token, el, method);
      }
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To update the edit of the Checkout (payment method and Active fields) to the database

export const updateCheckout = async function (editedCheckout) {
  try {
    // Convert to formats suitable to be populated to database*
    state.checkout.editDbResults = editedCheckout.map(bill => {
      const checkoutdetailsArr = [];
      bill.checkoutDetails.map(pkg => {
        const checkoutdetails =
          pkg.item === null
            ? { package: pkg.package.package_id }
            : { item: pkg.item };
        checkoutdetailsArr.push(checkoutdetails);
      });
      // UI update returns paymentMethod (name), and Manual Pay returns payment_method (id).
      // Thus if payment_method exists, use direct, else retrieve the payment method id for the selected payment method name. If payment method is not selected, default it to 'Credit-UOB' by looking for its id
      const payment_method = bill.payment_method
        ? bill.payment_method
        : state.checkout.paymentMethod.find(
            x => x.category === bill.paymentMethod
          ).id;

      return {
        checkout_id: bill.id,
        payment_method: payment_method,
        checkoutdetails: checkoutdetailsArr,
        active: bill.billActive,
        transact_status: bill.transactStatus,
      };
    });

    // console.log(state.checkout.editDbResults);
    //Call AJAX to update the edit to database using PUT method. Access token is retrieved from local Storage.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'PUT';
    const editDetails = state.checkout.editDbResults;
    for (let el of editDetails) {
      let data = await AJAX(
        `${API_CREATECHECKOUT_URL}${el.checkout_id}/`,
        token,
        el,
        method
      );
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To update the edit of the Customer to the database

export const updateCustomer = async function (editedCustomer) {
  try {
    // Convert to formats suitable to be populated to database
    state.customer.editDbResults = editedCustomer.map(cust => {
      return {
        customer_id: cust.id,
        first_name: cust.custFirstName,
        last_name: cust.custLastName,
        nick_name: cust.custNickName,
        gender: cust.custGender,
        mobile: cust.custMobile,
        date_of_birth: cust.custBirthday,
        email: cust.custEmail,
        address: cust.custAddress,
        unit_no: cust.custUnitNo,
        country: cust.custCountry,
        postcode: cust.custPostcode,
        active: cust.custActive,
      };
    });
    //Call AJAX to update the edit to database using PUT method. Access token is retrieved from local Storage.

    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'PUT';
    const editDetails = state.customer.editDbResults;
    for (let el of editDetails) {
      let data = await AJAX(
        `${API_CUSTOMER_URL}${el.customer_id}/`,
        token,
        el,
        method
      );
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To update the edit of the Treatment to the database

export const updateTreatment = async function (editedTreatment) {
  try {
    // Convert to formats suitable to be populated to database. Find the category id of which the category name matches that of the records that are edited
    state.treatment.editDbResults = editedTreatment.map(treat => {
      const categoryID = state.treatment.treatmentCategory.find(
        x => x.category === treat.treatCategory
      );

      return {
        treatment_id: treat.id,
        treatment_name: treat.treatName,
        category: categoryID.id,
        active: treat.treatActive,
      };
    });

    //Call AJAX to update the edit to database using PUT method. Access token is retrieved from local Storage.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'PUT';
    const editDetails = state.treatment.editDbResults;
    for (let el of editDetails) {
      let data = await AJAX(
        `${API_TREATMENT_URL}${el.treatment_id}/`,
        token,
        el,
        method
      );
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To update the edit of the TItem to the database

export const updateItem = async function (editedItem) {
  try {
    // Convert to formats suitable to be populated to database. Find the category id of which the category name matches that of the records that are edited
    state.item.editDbResults = editedItem.map(item => {
      const categoryID = state.item.itemCategory.find(
        x => x.category === item.itemCategory
      );
      const itemSizeID = state.item.itemSize.find(
        x => x.size === item.itemSize
      );

      return {
        item_id: item.id,
        item_code: item.itemCode,
        item_name: item.itemName,
        item_description: item.itemDescription,
        sku_number: item.skuNumber,
        unit_price: item.unitPrice,
        item_category: categoryID.id,
        item_size: itemSizeID.id,
        item_color: item.itemColor,
        active: item.itemActive,
      };
    });

    //Call AJAX to update the edit to database using PUT method. Access token is retrieved from local Storage.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'PUT';
    const editDetails = state.item.editDbResults;
    for (let el of editDetails) {
      let data = await AJAX(`${API_ITEM_URL}${el.item_id}/`, token, el, method);
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/* ADD NEW RECORD*/

//To add a new package entered via UI form
export const addNewPackage = async function (newPackage) {
  try {
    // To convert the package details to the correct format
    const packagedetails = Object.entries(newPackage) //convert Object to Array
      .filter(pkg => pkg[0].startsWith('ctreatment') && pkg[1] !== '') // to pick out any array with first element starting with ctreatment, and which has a treatment id in the second element e.g  ['ctreatment2', '128,1']
      .map(pkg => {
        const pkgArr = pkg[1].split(',').map(el => el.trim());
        //Check for package entry format
        // if (ingArr.length !== 3) {
        //   throw new Error();
        // }
        const [treatment, qty_usage] = pkgArr;
        return { treatment: +treatment, qty_usage: +qty_usage };
      });

    // To convert the package references to the correct format
    const packagereferences = Object.entries(newPackage) //convert Object to Array
      .filter(pkg => pkg[0].startsWith('cpackageref') && pkg[1] !== '') // to pick out any array with first element starting with ctreatment, and which has a treatment id in the second element e.g  ['ctreatment2', '128,1']
      .map(pkg => {
        const pkgArr = pkg[1].split(',').map(el => el.trim());
        const [reftype, ref_value] = pkgArr;
        return { reftype: +reftype, ref_value: ref_value };
      });

    // Auto-populate Total Price to be = Price Paid if Total Price is left blank
    const totalPrice =
      newPackage.totalPrice === ''
        ? newPackage.priceBought
        : newPackage.totalPrice;

    // Auto-populate Total Price to be = Price Paid if Total Price is left blank
    const totalPriceTax =
      newPackage.totalPricewTax === ''
        ? newPackage.priceBoughtwTax
        : newPackage.totalPricewTax;

    // Calculate the Balance inclusive of Tax if exists
    const paymentBalance =
      newPackage.totalPrice === ''
        ? 0
        : (newPackage.totalPricewTax - newPackage.priceBoughtwTax).toFixed(2);

    // track the login user to be inserted into the added package info. This can be used to track the branch name as well.
    const userId = state.user.ssoResults.id;
    // to insert the branch based on the login user > organization branch
    const branch = state.user.ssoResults.organization.branch_name;

    // to assign package status to Pending for package added via Add to Cart
    const packageStatus = newPackage.packageStatus
      ? state.package.status.find(x => x.status === newPackage.packageStatus).id
      : state.package.status.find(x => x.status === 'New').id;

    //convert to API package format
    const packageCreated = {
      customer: +newPackage.custID,
      user: userId,
      branch: branch,
      date_bought: newPackage.dateBought,
      qty_bought: +newPackage.qtyBought,
      qty_balance: +newPackage.qtyBought, //initial qty balance will be same as qty bought
      price_bought: newPackage.priceBought,
      price_bought_tax: newPackage.priceBoughtwTax,
      total_price: totalPrice,
      total_price_tax: totalPriceTax,
      payment_balance: paymentBalance,
      price_balance: newPackage.priceBought, //initial price balance will be same as price bought
      status: packageStatus,
      packagedetails,
      packagereferences,
    };

    //Upload new package by calling sendJSON to post it via API call
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'POST';
    const uploaddata = await AJAX(
      `${API_PACKAGES_URL}`,
      token,
      packageCreated,
      method
    );

    // assign the returned uploaded Package API results to a global variable so that controller can use it to call addNewCheckout
    state.package.uploadedPackage = uploaddata;

    state.package.uploadedPackage.payment_method = +newPackage.paymentMethod; // to add the selected payment method in New Package to the Checkout to be created
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To add a new cart item
export const addNewCart = async function (newCart) {
  try {
    // console.log(newCart);

    // to assign either catalog or package id depending on which is added to the cart. Assign null as the value otherwise.
    const catalogId = newCart.catId ? newCart.catId : null;
    const packageId = newCart.package_id ? newCart.package_id : null;

    const cartCreated = {
      user: state.user.results.id,
      catalog: catalogId,
      package: packageId,
    };

    //Upload new treatment by calling sendJSON to post it via API call
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'POST';
    const uploaddata = await AJAX(
      `${API_CREATECART_URL}`,
      token,
      cartCreated,
      method
    );
    state.cart.update = uploaddata;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To add a new item purchase so that it can be added to the checkout Bill
export const addNewItemPurchase = async function (newCart) {
  try {
    state.item.itemPurchase = []; // need to set this to blank array first if not it will keep adding up from previous transaction
    const user = state.user.results.id;
    const customer = newCart.customer;

    const itemPurchase = Object.entries(newCart) //convert Object to Array
      .filter(cart => cart[0].startsWith('itempurchase')) // to pick out any array with first element starting with itempurchase, and which has a item id in the second element e.g  ['ctreatment2', '128,1']
      .map(cart => {
        const itemPurchaseArr = cart[1].split(',').map(el => el.trim());
        const [item, qty, price, pricetax] = itemPurchaseArr;
        return {
          item: +item,
          qty: +qty,
          price: +price,
          price_tax: +pricetax,
          user: user,
          customer: customer,
        };
      });

    // console.log(itemPurchase);

    // Upload new item purchases by calling sendJSON to post it via API call
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'POST';
    for (let el of itemPurchase) {
      let data = await AJAX(`${API_ITEMPURCHASE_URL}`, token, el, method);
      state.item.itemPurchase.push(data); // add to an array to be used by addCheckout
    }
    // console.log(state.item.itemPurchase);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To add a new checkout of bill type Receipt based on entry of any sub Payment
export const addNewCheckoutSubPayment = async function (newItem) {
  try {
    // to retrieve the payment status from database
    const query = 'Payment Status';
    await loadPaymentStatus(query);

    // to retrieve the bill type from database
    const query1 = 'Bill Type';
    await loadBillType(query1);

    // Convert to formats suitable to be populated to database
    state.checkout.subPayment = newItem
      .filter(pkg => pkg.isSubsequentPayment) //To filter out only those objects with updated sub payment
      .map(pkg => {
        const checkoutdetailsArr = [];
        const checkoutdetails = { package: pkg.id };
        checkoutdetailsArr.push(checkoutdetails);

        // track the login user to be inserted into the added package info. This can be used to track the branch name as well.
        const userId = state.user.ssoResults.id;

        const orderNumber = addUniqueNumber();

        // to assign the payment status based on total price vs paid price. The payment status id is found via API call based on Payment Status Description
        const payment_status =
          pkg.paymentBalance > 0
            ? state.checkout.paymentStatus.find(x => x.status === 'Open').id
            : state.checkout.paymentStatus.find(x => x.status === 'Paid').id;

        // to retrieve the bill id for bill type name = Receipt
        const bill_type = state.checkout.billType.find(
          x => x.type === 'Receipt'
        ).id;

        // to retrieve the payment method id for the selected payment method. If payment method is not selected, default it to 'Credit-UOB' by looking for its id
        const payment_method =
          pkg.payment_method === undefined
            ? state.checkout.paymentMethod.find(
                x => x.category === 'Credit-UOB'
              ).id
            : state.checkout.paymentMethod.find(
                x => x.category === pkg.payment_method
              ).id;

        return {
          customer: pkg.custID,
          checkoutdetails: checkoutdetailsArr,
          total_price: pkg.totalPrice,
          payment_status: payment_status,
          payment_method: payment_method,
          user: userId,
          subsequent_payment: pkg.subPayment,
          payment_balance: pkg.paymentBalance,
          bill_type: bill_type,
          order_number: orderNumber,
        };
      });

    // console.log(state.checkout.subPayment);

    // Upload new checkout by calling sendJSON to post it via API call
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'POST';
    const subPaymentDetails = state.checkout.subPayment;
    for (let el of subPaymentDetails) {
      let data = await AJAX(`${API_CREATECHECKOUT_URL}`, token, el, method);
      // insert a new object key "csplitpayment1" so that el can be sent as a variable to calcPaymentFee to calculate the payment fee and insert into Payment
      el.csplitpayment1 =
        // 0 refers to null Payment Brand and 1 is the line number
        el.subsequent_payment + ',' + el.payment_method + ',' + '0' + ',' + '1';
      el.priceBoughtwTax = el.subsequent_payment;
      state.checkout.uploadedCheckout = data;
      await calcPaymentFee(el);
      await addNewPayment(state.payment.uploadedPayment);
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To create a new Checkout (Bill). This module is called upon submission of a new package, or when user goes via the Add Cart route to perform a Manual/Online Pay
// The check on 'paymentemail' in newItem is to differentiate the above 2 scenarios. If 'paymentemail' is present, it is from the Manual/Online Pay.
export const addNewCheckout = async function (newItem) {
  try {
    // Get the Order Number generated from Cart Checkout , else for direct package creation, generate a new Order Number by calling addUniqueNumber function
    const orderNumber =
      state.checkout.orderNumber.length !== 0
        ? state.checkout.orderNumber
        : addUniqueNumber();
    const checkoutdetailsArr = [];
    const user = state.user.results.id;
    const payment_method_online = state.checkout.paymentMethod.find(
      x => x.category === 'Credit-Online'
    ).id;
    const payment_method_terminal = state.checkout.paymentMethod.find(
      x => x.category === 'Credit-Terminal'
    ).id;
    // For Cart Payment, filter the package-related items in the cart
    const packageCheckout =
      'paymentemail' in newItem
        ? Object.entries(newItem) //convert Object to Array
            .filter(cart => cart[0].startsWith('packagepurchase')) // to pick out any array with first element starting with ctreatment, and which has a treatment id in the second element e.g  ['ctreatment2', '128,1']
            .map(cart => {
              const packageCheckoutArr = cart[1]
                .split(',')
                .map(el => el.trim());
              const [package_id, price_bought, total_price] =
                packageCheckoutArr;
              return {
                package_id: +package_id,
                price_bought: +price_bought,
                total_price: +total_price,
              };
            })
        : '';
    // For Cart Payment
    if ('paymentemail' in newItem) {
      // To add the purchased package id into the checkout Arr
      packageCheckout
        .map(pkg => {
          return {
            package: pkg.package_id,
          };
        })
        .forEach(pkg => checkoutdetailsArr.push(pkg));

      // To add the purchased item id into the checkout Arr
      state.item.itemPurchase
        .map(item => {
          return {
            item: item.item_purchase_id,
          };
        })
        .forEach(item => checkoutdetailsArr.push(item));
      // console.log(checkoutdetailsArr);

      // This part is for normal Add Package
    } else {
      // checkoutdetails must be an array, thus we take the package_id and push it into the array.
      const checkoutdetails = { package: newItem.package_id };
      // To convert the checkout details to the correct format
      checkoutdetailsArr.push(checkoutdetails);
    }

    // Retrieve the total price from cart total price if it is Cart Purchase else get the total price from the package total price
    const totalPrice =
      'paymentemail' in newItem ? newItem.carttotalprice : newItem.total_price;

    // to retrieve the payment status from database
    const query = 'Payment Status';
    await loadPaymentStatus(query);

    // to retrieve the bill type from database
    const query1 = 'Bill Type';
    await loadBillType(query1);

    // to assign the payment status based on total price vs paid price. The payment status id is found via API call based on Payment Status Description
    const payment_status =
      (packageCheckout !== '' &&
        packageCheckout.some(pkg => pkg.total_price > pkg.price_bought)) ||
      (packageCheckout === '' && newItem.total_price > newItem.price_bought)
        ? state.checkout.paymentStatus.find(x => x.status === 'Open').id
        : state.checkout.paymentStatus.find(x => x.status === 'Paid').id;

    // to retrieve the bill id for bill type name = Bill
    const bill_type = state.checkout.billType.find(x => x.type === 'Bill').id;

    // Get the current date if it is from Cart Purchase, else use the Package Purchase Date
    const bill_date =
      'paymentemail' in newItem
        ? new Date().toISOString().slice(0, 10) // insert current date
        : newItem.date_bought;

    const customer = newItem.customer;

    // console.log(state.checkout.paymentMethod);
    let payment_method;
    if (newItem.transactMethod === 'online') {
      payment_method = +payment_method_online;
    } else if (newItem.transactMethod === 'terminal') {
      payment_method = +payment_method_terminal;
    } else if (newItem.transactMethod === 'manual') {
      payment_method = +newItem.splitpaymentmethod_1;
    } else {
      payment_method = +newItem.payment_method;
    }
    // To use checkout split payment 1 as the payment method if it is coming from Cart Checkout, if it is from package, use package payment method
    // const payment_method =
    //   'paymentemail' in newItem
    //     ? +newItem.splitpaymentmethod_1
    //     : +newItem.payment_method;

    // To assign the Payment Transaction Status for Online Payment based on status provided by provider. Initial status will be "pending"
    const transact_status =
      'transactStatus' in newItem && newItem.transactStatus !== null
        ? titleCase(newItem.transactStatus)
        : null;

    //convert to API package format
    const checkoutCreated = {
      customer: customer,
      user: user,
      order_number: orderNumber,
      checkoutdetails: checkoutdetailsArr,
      total_price: totalPrice,
      payment_method: payment_method,
      payment_status: payment_status,
      transact_status: transact_status,
      // subsequent_payment: newItem.price_bought, // so that all price paid will be in this one single field for both Bill and Receipt type
      bill_type: bill_type,
      bill_date: bill_date, // to make the bill date equal to the buy date
    };

    // console.log(checkoutCreated);

    // Upload new checkout by calling sendJSON to post it via API call
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'POST';
    const uploaddata = await AJAX(
      `${API_CREATECHECKOUT_URL}`,
      token,
      checkoutCreated,
      method
    );
    // console.log(checkoutCreated);

    // assign the created Checkout to the array variable
    state.checkout.uploadedCheckout = uploaddata;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/* ONLINE & IN-PERSON PAYMENT */

//To generate unique Order Number and show in Cart Checkout UI
export const addReadyForNewPayment = async function () {
  try {
    // Call function to generate unique Order Number
    const orderNumber = addUniqueNumber();
    state.checkout.orderNumber = orderNumber;
    document.querySelector('.cartcheckout-orderno').innerHTML = orderNumber;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const onSuccess = function (paymentRequestResponse) {
  document.dispatchEvent(
    new CustomEvent('paymentResult', {
      detail: paymentRequestResponse.status,
    })
  );
};

//To create the Hitpay payment request
export const addNewOnlinePayment = async function () {
  try {
    // If the payment records based on order number is existing, find the max "-x", else set it to 0, so that we can set the new payment number to be x + 1
    const paymentNumberMaxIndex =
      state.payment.results.length !== 0
        ? state.payment.results
            .map(arr => {
              const paymentNumberIndex = arr.paymentNumber.split('-')[1];
              return {
                index: paymentNumberIndex,
              };
            })
            .reduce((prev, curr) => (prev > curr.index ? prev : curr.index), 0)
        : 0;

    const newPaymentNumberIndex = +paymentNumberMaxIndex + 1;
    const currency = 'SGD';
    const amount = +document
      .querySelector('.cartcheckout-grandtotal-price')
      .innerHTML.replace(/[^0-9.-]+/g, '');
    // const amount = +document.querySelector(
    //   '.add-cartcheckout-form-splitpayment1'
    // ).value;
    const email = document.querySelector(
      '.add-cartcheckout-form-paymentemail'
    ).value;
    const mobile = document.querySelector(
      '.add-cartcheckout-form-paymentmobile'
    ).value;
    const orderNumber = document.querySelector(
      '.cartcheckout-orderno'
    ).innerHTML;
    const paymentNumber = orderNumber + '-' + newPaymentNumberIndex;
    state.payment.paymentNumber = paymentNumber;

    const paymentMethod = [];
    const terminalId = '';
    const paymentData = {
      amount: amount,
      currency: currency,
      paymentNumber: paymentNumber,
      email: email,
      mobile: mobile,
      paymentMethod: paymentMethod,
      terminalId: terminalId,
    };
    // Call the Hitpay Payment Methods API  to retrieve the payment methods available in the indicated country
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const apiMethod = 'POST';
    const paymentRequestResponse = await AJAX(
      `${API_PAYMENT_REQUEST}`,
      token,
      paymentData,
      apiMethod
    );

    if (paymentRequestResponse.url) {
      // Initialise HitPay Drop-in

      // const defaultLink =
      //   'https://securecheckout.sandbox.hit-pay.com/payment-request/@true-shape-pte-ltd'; // for sandbox
      const defaultLink =
        'https://securecheckout.hit-pay.com/payment-request/@true-shape-pte-ltd'; // for production

      HitPay.init(
        defaultLink,
        {
          // domain: 'sandbox.hit-pay.com', //mandatory for sandbox
          // apiDomain: 'sandbox.hit-pay.com', //mandatory for sandbox
          domain: 'hit-pay.com', //mandatory for production
          apiDomain: 'hit-pay.com', //mandatory for production
        },
        {
          // Optional
          // closeOnError: true,
        },
        // Optional callbacks
        {
          // onClose: onClose(),
          // onSuccess: onSuccess,
          onSuccess: onSuccess(paymentRequestResponse),
          onError: function (error) {
            console.log(`Error: ${error}`);
          },
        }
      );
      // Show Drop In UI
      HitPay.toggle({
        paymentRequest: paymentRequestResponse.id,
      });
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To create the Hitpay Payment Request to Terminal
export const addNewTerminalPayment = async function () {
  try {
    // If the payment records based on order number is existing, find the max "-x", else set it to 0, so that we can set the new payment number to be x + 1
    const paymentNumberMaxIndex =
      state.payment.results.length !== 0
        ? state.payment.results
            .map(arr => {
              const paymentNumberIndex = arr.paymentNumber.split('-')[1];
              return {
                index: paymentNumberIndex,
              };
            })
            .reduce((prev, curr) => (prev > curr.index ? prev : curr.index), 0)
        : 0;

    const newPaymentNumberIndex = +paymentNumberMaxIndex + 1;

    const currency = 'SGD';
    // const amount = +document
    //   .querySelector('.cartcheckout-grandtotal-price')
    //   .innerHTML.replace(/[^0-9.-]+/g, '');
    const amount = +document.querySelector(
      '.add-cartcheckout-form-splitpayment1'
    ).value;
    const email = document.querySelector(
      '.add-cartcheckout-form-paymentemail'
    ).value;
    const mobile = document.querySelector(
      '.add-cartcheckout-form-paymentmobile'
    ).value;
    const orderNumber = document.querySelector(
      '.cartcheckout-orderno'
    ).innerHTML;
    const paymentNumber = orderNumber + '-' + newPaymentNumberIndex;
    state.payment.paymentNumber = paymentNumber;
    const paymentMethod = ['wifi_card_reader'];
    const terminalId = 'tmr_Fxa3mQaOYx6VPa'; //'tmr_FwnaBAOUwqAZJT' (sandbox)
    const paymentData = {
      amount: amount,
      currency: currency,
      paymentNumber: paymentNumber,
      email: email,
      mobile: mobile,
      paymentMethod: paymentMethod,
      terminalId: terminalId,
    };

    // Call the Hitpay Payment Methods API  to retrieve the payment methods available in the indicated country
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const apiMethod = 'POST';
    const paymentRequestResponse = await AJAX(
      `${API_PAYMENT_REQUEST}`,
      token,
      paymentData,
      apiMethod
    );

    // console.log(paymentRequestResponse);
    // create a custom event called paymentResultTerminal (addPaymentView.js) in order to trigger the following actions upon terminal paymentResponse return
    document.dispatchEvent(
      new CustomEvent('paymentResultTerminal', {
        detail: paymentRequestResponse.status,
      })
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To retrieve and calculate the payment fees
export const calcPaymentFee = async function (newPayment) {
  try {
    // To retreive the total paid amount of the package from the checkout total price and populate to all split payments
    // Package added from Add Package and Subsequent Payment will have priceBoughtwTax field, whereas package from add cart will take total paid amount from the cartgrandprice
    const totalPaidAmt = newPayment.priceBoughtwTax
      ? newPayment.priceBoughtwTax
      : +newPayment.cartgrandprice.toFixed(2);

    // To retrieve cust mobile from checkout
    const mobile = state.checkout.uploadedCheckout.customer
      ? state.checkout.uploadedCheckout.customer_mobile
      : newPayment.paymentmobile;

    // To retrieve the Credit-Online payment method id
    const payment_method_online = state.checkout.paymentMethod.find(
      x => x.category === 'Credit-Online'
    ).id;

    // To retrieve the Credit-Online payment method id
    const payment_method_terminal = state.checkout.paymentMethod.find(
      x => x.category === 'Credit-Terminal'
    ).id;

    // For online and terminal payment, retrieve only the first split payment amount and ignore the rest since split payment is not allowed for online payment
    const csplitpaymentOption =
      newPayment.transactMethod === 'online' ||
      newPayment.transactMethod === 'terminal'
        ? 'csplitpayment1'
        : 'csplitpayment';

    // To convert the split payment details to the correct format
    const splitpaymentdetails = Object.entries(newPayment) //convert Object to Array
      .filter(pkg => pkg[0].startsWith(csplitpaymentOption) && pkg[1] !== '') // to pick out any array with first element starting with csplitpayment, and which has a payment amt in the second element e.g  ['csplitpayment', '100']
      .map(pkg => {
        const pkgArr = pkg[1].split(',').map(el => el.trim());
        const [amount, payment_method, payment_brand, line_no] = pkgArr;
        let paymentMethod;
        // To change payment method to Credit Online or Credit Terminal if it is online or terminal payment
        if (newPayment.transactMethod === 'online') {
          paymentMethod = payment_method_online;
        } else if (newPayment.transactMethod === 'terminal') {
          paymentMethod = payment_method_terminal;
        } else {
          paymentMethod = payment_method;
        }

        return {
          line_no: line_no,
          amount: +amount,
          payment_method: +paymentMethod,
          payment_brand: +payment_brand,
        };
      });

    const query = '#';
    // Call the Payment Fee API  to retrieve the payment fee (retrieve all)
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const data = await AJAX(`${API_PAYMENTFEE_URL}?search=${query}`, token);

    //  To create the payment object
    const payment = splitpaymentdetails.map(pay => {
      // if payment brand =0 (user did not select and stay as Pls select), change it to null

      pay.payment_brand = pay.payment_brand === 0 ? null : pay.payment_brand;
      // pay.payment_brand = null; // Set to null for all if payment brand is not used in Fee calculation

      // Form the payment number by concatenating the order number and split payment line number
      const paymentNumber =
        newPayment.transactMethod === 'terminal' ||
        newPayment.transactMethod === 'online'
          ? state.payment.paymentNumber
          : state.checkout.uploadedCheckout.order_number + '-' + pay.line_no;

      const paidAmount = +pay.amount.toFixed(2); // convert to 2 decimals place
      // Filter the array where the payment method and payment brand equal
      let paymentFeeObject = data
        .filter(rate => {
          return (
            rate.payment_method === pay.payment_method
            // && rate.payment_brand === pay.payment_brand // Currently payment brand is not needed to retrieve payment fees
          );
        })
        // Calculate the fees
        .map(arr => {
          const variableRate = +arr.variable_rate;
          const fixedFee = +arr.fixed_fee;
          const discountFee = +((arr.variable_rate / 100) * pay.amount).toFixed(
            2
          );
          const totalFee = +(fixedFee + discountFee).toFixed(2);
          const finalTotalFee =
            totalFee > +arr.min_fee ? totalFee : +arr.min_fee; // take the higher bet min fee and total fee
          const netAmount = +(+pay.amount - finalTotalFee).toFixed(2);

          return {
            variableRate: variableRate,
            fixedFee: fixedFee,
            discountFee: discountFee,
            totalFee: finalTotalFee,
            netAmount: netAmount,
          };
        });

      // If transact method is online, change payment method to Credit-Online
      const paymentMethod =
        newPayment.transactMethod === 'online'
          ? payment_method_online
          : pay.payment_method;

      // To assign the Payment Transaction Status for Online/Terminal Payment based on status provided by provider. Initial status will be "pending"
      const transactStatus =
        'transactStatus' in newPayment && newPayment.transactStatus !== null
          ? titleCase(newPayment.transactStatus)
          : null;

      // To handle exceptions in which the payment fee cannot be found, set all the fees to 0
      const finalFixedFee =
        paymentFeeObject.length === 0 ? 0 : paymentFeeObject[0].fixedFee;
      const finalVariableRate =
        paymentFeeObject.length === 0 ? 0 : paymentFeeObject[0].variableRate;
      const finalDiscountFee =
        paymentFeeObject.length === 0 ? 0 : paymentFeeObject[0].discountFee;
      const grandTotalFee =
        paymentFeeObject.length === 0 ? 0 : paymentFeeObject[0].totalFee;
      const finalNetAmount = +(+pay.amount - grandTotalFee).toFixed(2);

      // return the final payment object
      return {
        payment_number: paymentNumber,
        name: state.checkout.uploadedCheckout.customer_nickName,
        mobile: mobile,
        payment_date: state.checkout.uploadedCheckout.bill_date,
        checkout: state.checkout.uploadedCheckout.checkout_id,
        total_amount: totalPaidAmt,
        amount: paidAmount,
        payment_method: paymentMethod,
        payment_brand: pay.payment_brand,
        fixed_fee: finalFixedFee,
        discount_fee_rate: finalVariableRate,
        discount_fee: finalDiscountFee,
        total_fee: grandTotalFee,
        net_amount: finalNetAmount,
        transact_status: transactStatus,
      };
    });
    state.payment.uploadedPayment = payment;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To insert the new Payment to database
export const addNewPayment = async function (newPayment) {
  try {
    // Post to the Payment API
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const apiMethod = 'POST';
    for (let el of newPayment) {
      let uploadedPayment = await AJAX(
        `${API_PAYMENT_URL}`,
        token,
        el,
        apiMethod
      );
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// //To create the Adyen credit card form
// export const addNewOnlinePayment = async function () {
//   try {
//     const currentUrl = window.location.origin;

//     // Call function to generate unique Order Number
//     const orderNumber = addUniqueNumber();
//     state.checkout.orderNumber = orderNumber;
//     document.querySelector('.cartcheckout-orderno').innerHTML = orderNumber;
//     // const shoppermobilePhone = document.querySelector(
//     //   '.add-cartcheckout-form-paymentmobile'
//     // ).value;
//     const amount =
//       +document
//         .querySelector('.cartcheckout-grandtotal-price')
//         .innerHTML.replace(/[^0-9.-]+/g, '') * 100;
//     const currency = 'SGD';
//     const paymentAmt = {
//       amount: amount,
//       currency: currency,
//     };

//     // Call the Adyen Payment Methods API  to retrieve the payment methods available in the indicated country
//     const token = localStorage.getItem('accesstoken').slice(1, -1);
//     const apiMethod = 'POST';
//     const paymentMethodsResponse = await AJAX(
//       `${API_PAYMENT_METHOD_SESSION}`,
//       token,
//       paymentAmt,
//       apiMethod
//     );

//     // console.log(paymentMethodsResponse);

//     const configuration = {
//       locale: 'en_US',
//       environment: 'test',
//       clientKey: 'test_SW6WIJXIUJD7FIZIY6IZD5NJIUDPQIRV',
//       countryCode: 'SG',
//       paymentMethodsResponse: paymentMethodsResponse, // include the payment method response in above API call to be part of config for the payment
//       amount: {
//         currency: currency,
//         value: amount,
//       },
//       // to handle the events upon clicking on Pay
//       onSubmit: async (state, component, actions) => {
//         try {
//           const shopperEmail = document.querySelector(
//             '.add-cartcheckout-form-paymentemail'
//           ).value;
//           const method = 'POST';
//           // Below are details required in order to increase the chance of payment authorised without further authentication
//           const paymentData = {
//             orderNumber: orderNumber,
//             amount: amount,
//             currency: currency,
//             paymentMethod: state.data.paymentMethod,
//             browserInfo: state.data.browserInfo,
//             holderName: state.data.paymentMethod.holderName,
//             currentUrl: currentUrl,
//             shopperEmail: shopperEmail,
//           };
//           // Call the Adyen payment API to authorise the payment
//           const paymentResponse = await AJAX(
//             `${API_PAYMENT_SESSION}`,
//             token,
//             paymentData,
//             method
//           );

//           // console.log(state.data);
//           // console.log(paymentResponse);

//           // create a custom event called paymentResult (addPaymentView.js) in order to trigger the following actions upon paymentResponse return
//           if (!paymentResponse.action) {
//             document.dispatchEvent(
//               new CustomEvent('paymentResult', {
//                 detail: paymentResponse.resultCode,
//               })
//             );
//             // component.setStatus('ready'); // Stop the loading state
//           } else if (paymentResponse.action) {
//             component.handleAction(paymentResponse.action);
//           }

//           // if payment is authorised
//           if (paymentResponse.resultCode === 'Authorised') {
//             card.unmount(); // to remove the card component from the div
//             // if payment required further authentication. Below handleAction will call onAdditionalDetails to pass the additional info entered by user
//           } else if (paymentResponse.resultCode === 'Refused') {
//             // to reset the card component fields by unmount and mount again
//             card.unmount();
//             card.mount('#cardcomponent-container');
//           }
//           // If the /payments request from your server fails, or if an unexpected error occurs.
//           if (!paymentResponse.resultCode) {
//             actions.reject();
//             return;
//           }
//         } catch (error) {
//           console.error('onSubmit', error);
//           actions.reject();
//         }
//       },
//       onAdditionalDetails: async (state, component, actions) => {
//         try {
//           const method = 'POST';
//           const paymentData = {
//             paymentDetails: state.data.details,
//           };
//           // Call the Adyen Payment Details API to pass the additional authentication info entered by the user to authorise the payment
//           const paymentDetailResponse = await AJAX(
//             `${API_PAYMENT_DETAILS_SESSION}`,
//             token,
//             paymentData,
//             method
//           );
//           // console.log(paymentDetailResponse);

//           // create a custom event called paymentResult (addPaymentView.js) in order to trigger the following actions upon paymentResponse return
//           document.dispatchEvent(
//             new CustomEvent('paymentResult', {
//               detail: paymentDetailResponse.resultCode,
//             })
//           );
//           // if payment is authorised
//           if (paymentDetailResponse.resultCode === 'Authorised') {
//             card.unmount(); // to remove the card component from the div
//             // if payment required further authentication. Below handleAction will call onAdditionalDetails to pass the additional info entered by user
//           } else if (paymentDetailResponse.resultCode === 'Refused') {
//             // to reset the card component fields by unmount and mount again
//             card.unmount();
//             card.mount('#cardcomponent-container');
//           }
//         } catch (error) {
//           console.error('onAdditionalDetails', error);
//           actions.reject();
//         }
//       },
//       onPaymentCompleted: (result, component) => {
//         console.log(result, component);
//       },
//       analytics: {
//         enabled: false, // Set to false to not send analytics data to Adyen.
//       },
//       onPaymentFailed: (result, component) => {
//         console.log(result, component);
//       },
//       onError: (error, component) => {
//         console.log(error.name, error.message, component);
//       },
//     };

//     // Mount the above config to an HTML div to show it
//     const checkout = await AdyenCheckout(configuration);

//     // To show the Card component only in this HTML div. You can mount/show other components e.g AliPay to other HTML div
//     const card = new Card(checkout, {
//       hasHolderName: true,
//       holderNameRequired: true,
//       // billingAddressRequired: true,
//     }).mount('#cardcomponent-container'); // This means adding only credit card payment method here. You can use new Alipay example to add other payment method anywhere on your web.
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// };

// //To create the Adyen credit card form
// export const addNewTerminalPayment = async function () {
//   try {
//     // Call function to generate unique Order Number
//     const serviceId = addUniqueId10Char();
//     const orderNumber = document.querySelector(
//       '.cartcheckout-orderno'
//     ).innerHTML;
//     const currentTimeStamp = new Date().toISOString();
//     const amount = +document
//       .querySelector('.cartcheckout-grandtotal-price')
//       .innerHTML.replace(/[^0-9.-]+/g, '');
//     const currency = 'SGD';
//     const paymentInfo = {
//       amount: amount,
//       currency: currency,
//       serviceId: serviceId,
//       orderNumber: orderNumber,
//       currentTimeStamp: currentTimeStamp,
//     };

//     // Call the Adyen Payment Methods API  to retrieve the payment methods available in the indicated country
//     const token = localStorage.getItem('accesstoken').slice(1, -1);
//     const apiMethod = 'POST';
//     const paymentTerminalResponse = await AJAX(
//       `${API_PAYMENT_TERMINAL_SESSION}`,
//       token,
//       paymentInfo,
//       apiMethod
//     );
//     console.log(paymentTerminalResponse);
//     // create a custom event called paymentResultTerminal (addPaymentView.js) in order to trigger the following actions upon terminal paymentResponse return
//     document.dispatchEvent(
//       new CustomEvent('paymentResultTerminal', {
//         detail:
//           paymentTerminalResponse.SaleToPOIResponse.PaymentResponse.Response
//             .Result,
//       })
//     );
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// };

//To add a new customer entered via UI form
export const addNewCustomer = async function (newCust) {
  try {
    //set birthday field to null if not entered by user as system will set it to "" by default which will result in error
    newCust.birthday = !newCust.birthday ? null : newCust.birthday;

    //convert to API package format
    const customerCreated = {
      first_name: newCust.firstname,
      last_name: newCust.lastname,
      nick_name: newCust.nickname,
      gender: newCust.gender,
      mobile: newCust.mobile,
      date_of_birth: newCust.birthday,
      email: newCust.email,
      address: newCust.address,
      unit_no: newCust.unitno,
      country: newCust.country,
      postcode: newCust.postcode,
    };

    //Upload new customer by calling sendJSON to post it via API call
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const method = 'POST';
    const uploaddata = await AJAX(
      `${API_CUSTOMER_URL}`,
      token,
      customerCreated,
      method
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To add a new treatment entered via UI form
export const addNewTreatment = async function (newTreat) {
  try {
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    const treatmentId = Object.fromEntries([...newTreat]).id; // to convert from FormData to object structure to retrieve the item id
    // //convert to API package format
    // const treatCreated = {
    //   treatment_name: newTreat.treatmentname,
    //   category: newTreat.treatmentcategory,
    // };

    //Upload new item or amend an existing item by calling sendFormData to post it via API call. FormData structure instead of Json is required for file upload
    // If item id is not existing, create a new item
    if (!treatmentId) {
      const method = 'POST';
      const uploaddata = await AJAXFILE(
        `${API_TREATMENT_URL}`,
        token,
        newTreat,
        method
      );
      // If item id is existing, amend the existing item
    } else {
      const method = 'PUT';
      const uploaddata = await AJAXFILE(
        `${API_TREATMENT_URL}${treatmentId}/`,
        token,
        newTreat,
        method
      );
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To add a new item entered via UI form OR to amend an existing item
export const addNewItem = async function (newItem) {
  try {
    const token = localStorage.getItem('accesstoken').slice(1, -1);

    const itemId = Object.fromEntries([...newItem]).id; // to convert from FormData to object structure to retrieve the item id

    // console.log(...newItem);

    //Upload new item or amend an existing item by calling sendFormData to post it via API call. FormData structure instead of Json is required for file upload
    // If item id is not existing, create a new item
    if (!itemId) {
      const method = 'POST';
      const uploaddata = await AJAXFILE(
        `${API_ITEM_URL}`,
        token,
        newItem,
        method
      );
      // If item id is existing, amend the existing item
    } else {
      const method = 'PUT';
      const uploaddata = await AJAXFILE(
        `${API_ITEM_URL}${itemId}/`,
        token,
        newItem,
        method
      );
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/* DROPDOWN LISTS*/

// To retrieve the dropdown options based on query = Keyfield
const loadDropDownOptions = async function (query) {
  try {
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET dropdown options API using search query and access token
    const data = await AJAX(`${API_DROPDOWNOPTION_URL}?search=${query}`, token);
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To retrieve the dropdown options based on query = Keyfield
export const loadRefType = async function (query) {
  try {
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET ref types API using search query and access token
    const data = await AJAX(`${API_REFTYPE_URL}?search=${query}`, token);
    state.package.refTypesName = data;

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the treatment category
export const loadTreatmentCategory = async function (query) {
  try {
    const data = await loadDropDownOptions(query);

    //To retrieve all the treatment category from database
    state.treatment.treatmentCategory = data.map(cat => {
      return {
        id: cat.dropdown_id,
        category: cat.option_list,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the Item category
export const loadItemCategory = async function (query) {
  try {
    const data = await loadDropDownOptions(query);

    //To retrieve all the item category from database
    state.item.itemCategory = data.map(cat => {
      return {
        id: cat.dropdown_id,
        category: cat.option_list,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the Item size
export const loadItemSize = async function (query) {
  try {
    const data = await loadDropDownOptions(query);
    //To retrieve all the item category from database
    state.item.itemSize = data
      .sort((a, b) => a.dropdown_id - b.dropdown_id) // sort according to dropdown id, so in order to appear from S to XL, it has to be added from S to XL in order in records
      .map(cat => {
        return {
          id: cat.dropdown_id,
          size: cat.option_list,
        };
      });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the package status
export const loadPackageStatus = async function (query) {
  try {
    const data = await loadDropDownOptions(query);

    //To retrieve all the package status from database
    state.package.status = data.map(stat => {
      return {
        id: stat.dropdown_id,
        status: stat.option_list,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the catalog status in Cart table
export const loadCatalogStatus = async function (query) {
  try {
    const data = await loadDropDownOptions(query);

    //To retrieve all the cart status from database
    state.cart.status = data.map(cart => {
      return {
        id: cart.dropdown_id,
        status: cart.option_list,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the payment method
export const loadPaymentMethod = async function (query) {
  try {
    const data = await loadDropDownOptions(query);

    //To retrieve all the payment method from database
    state.checkout.paymentMethod = data.map(cat => {
      return {
        id: cat.dropdown_id,
        category: cat.option_list,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the payment brand
export const loadPaymentBrand = async function (query) {
  try {
    const data = await loadDropDownOptions(query);

    //To retrieve all the payment method from database
    state.checkout.paymentBrand = data.map(cat => {
      return {
        id: cat.dropdown_id,
        category: cat.option_list,
      };
    });
    // to add a first select null option to the dropdown list to avoid default selection of the first option. unshift push it in the first element of array.
    state.checkout.paymentBrand.unshift({
      id: '0',
      category: '--Pls select--',
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the payment method
export const loadPaymentStatus = async function (query) {
  try {
    const data = await loadDropDownOptions(query);

    //To retrieve all the payment status from database
    state.checkout.paymentStatus = data.map(cat => {
      return {
        id: cat.dropdown_id,
        status: cat.option_list,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the bill type
export const loadBillType = async function (query) {
  try {
    const data = await loadDropDownOptions(query);

    //To retrieve all the payment status from database
    state.checkout.billType = data.map(cat => {
      return {
        id: cat.dropdown_id,
        type: cat.option_list,
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the package reference type
export const loadPackageRefType = async function (query) {
  try {
    const data = await loadRefType(query);

    //To retrieve all the reatment category from database
    state.package.refTypes = data.map(ref => {
      return {
        id: ref.reftype_id,
        reftype: ref.reftype_name,
      };
    });
    // to add a first select null option to the dropdown list to avoid default selection of the first option. unshift push it in the first element of array.
    state.package.refTypes.unshift({ id: '0', reftype: '--Pls select--' });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/* CHARTS*/

// To convert package objects array from database to formats suitable to be populated to tables. Return only fields required for charting.
const createChartObject = function (data) {
  return data.map(pkg => {
    //Change the object names and bring treatment name to the first array level via array map if there is only <= 1 treatment
    const [treatName] =
      pkg.packagedetails.length <= 1
        ? pkg.packagedetails.map(det => det.treatment_name)
        : 'M';

    // This needs to be calculated from the payment balance as only the latest subpayments are captured and not all subpayments are captured
    const totalPaid =
      +pkg.price_bought +
      (+pkg.total_price - +pkg.price_bought - +pkg.payment_balance);

    return {
      custName: pkg.customer_nickName,
      treatmentName: treatName,
      dateBought: pkg.date_bought,
      priceBought: pkg.price_bought,
      priceUsed: pkg.price_used,
      priceBalance: pkg.price_balance,
      totalPaid: totalPaid,
    };
  });
};

// API call to Package API for chart creation purposes
const loadChartSearchResults = async function (
  query,
  filterFromDate,
  filterToDate
) {
  try {
    // to retrieve the package status id from database
    const statusQuery = 'Package Status';
    await loadPackageStatus(statusQuery);

    // find the package status Pending id
    const packagePendingStatusId = state.package.status.find(
      x => x.status === 'Pending'
    ).id;
    state.search.query = query;

    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem('accesstoken').slice(1, -1);
    //GET packages API using search query and access token
    const data = await AJAX(
      `${API_PACKAGES_URL}?from_bought_date=${filterFromDate}&to_bought_date=${filterToDate}&search=${query}`,
      token
    );
    //Call the function to convert the Objects format to populate to tables, and filter out Pending status packages
    state.search.chartresults = createChartObject(
      data.filter(pkg => pkg.status !== packagePendingStatusId)
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the Sales Chart
export const loadSalesChartSearchResults = async function (
  query,
  filterFromDate,
  filterToDate
) {
  try {
    await loadChartSearchResults(query, filterFromDate, filterToDate);

    state.chart.monthlySales = createMonthlySalesObject(
      state.search.chartresults
    );
    state.chart.sortedMonthlySales = sortChartData(state.chart.monthlySales);
    state.chart.monthlySalesData = createChartDataArray(
      state.chart.sortedMonthlySales
    );
    // console.log(state.chart.monthlySalesData);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the Customer Sales Chart
export const loadCustomerChartSearchResults = async function (
  query,
  filterFromDate,
  filterToDate
) {
  try {
    await loadChartSearchResults(query, filterFromDate, filterToDate);

    state.chart.customerSales = createCustomerSalesObject(
      state.search.chartresults
    );

    state.chart.customerSalesData = sortChartDataByValueAsc(
      state.chart.customerSales
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the Bill Chart
export const loadCheckoutChartResults = async function (
  query,
  filterFromDate,
  filterToDate
) {
  try {
    await loadCheckoutSearchResults(query, filterFromDate, filterToDate);

    state.chart.monthlyBill = createMonthlyBillObject(state.checkout.results);
    state.chart.sortedMonthlyBill = sortChartData(state.chart.monthlyBill);
    state.chart.monthlyBillData = createChartDataArray(
      state.chart.sortedMonthlyBill
    );
    // console.log(state.chart.monthlyBillData);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// Using array reduce method to sum up the price bought based on date (yyyy-mm only) bought. Object.create(null) is required to make the initial value of acc as an empty Object
const createMonthlySalesObject = function (data) {
  return data.reduce((acc, obj) => {
    const key = obj.dateBought.substr(0, 7);
    // const value = Number.parseFloat(obj.priceBought).toFixed(2);
    acc[key] = (acc[key] || 0) + +obj.totalPaid;
    return acc;
  }, Object.create(null));
};

// Using array reduce method to sum up the price bought based on Customer Name. Object.create(null) is required to make the initial value of acc as an empty Object
const createCustomerSalesObject = function (data) {
  return data.reduce((acc, obj) => {
    const key = obj.custName;
    // const value = Number.parseFloat(obj.priceBought).toFixed(2);
    acc[key] = (acc[key] || 0) + +obj.totalPaid;
    return acc;
  }, Object.create(null));
};

// Using array reduce method to sum up the price bought based on date (yyyy-mm only) bought. Object.create(null) is required to make the initial value of acc as an empty Object
const createMonthlyBillObject = function (data) {
  return data.reduce((acc, obj) => {
    const key = obj.billDate.substr(0, 7);
    // const value = Number.parseFloat(obj.priceBought).toFixed(2);
    acc[key] = (acc[key] || 0) + +obj.billPaid;
    return acc;
  }, Object.create(null));
};

// To sort the data based on Object key which in this case is the Date. For Date sorting, only this method can work
const sortChartData = function (data) {
  return Object.keys(data)
    .sort()
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
};

// Object.entries will convert the single Object into an Array containing groups of each object as an array, and to sort the data (ascending order) based on the 1st element in each array which is the Object keys
const createChartDataArray = function (data) {
  return Object.entries(data);
};

// Object.entries will convert the single Object into an Array containing groups of each object as an array, and to sort the data (ascending order) based on the 2nd element in each array which is the Object values
const sortChartDataByValueAsc = function (data) {
  return Object.entries(data).sort((x, y) => x[1] - y[1]);
};
