import { TIMEOUT_SEC } from './config.js';

//Promisify setTimeout function (of 's' seconds wait) and assign to 'timeout' variable
const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`)); // s is set in config.js (set at 30 secs)
    }, s * 1000);
  });
};

//API for pure JSON for various url/methods/body data, including token fetch, and with timeout of 's' seconds
export const AJAX = async function (
  url,
  token = undefined,
  uploadData = undefined,
  fetchMethod
) {
  try {
    const fetchPro = uploadData
      ? fetch(url, {
          method: fetchMethod,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(uploadData),
        })
      : fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
    const data = await res.json(); //will return a status and uploaded data back
    if (!res.ok) throw new Error(`${data.message} (${res.status})`);
    return data;
  } catch (err) {
    throw err; //to throw the err to be handled within model.js instead of here
  }
};

//API to be used for File Upload for various url/methods/body data, including token fetch, and with timeout of 's' seconds
export const AJAXFILE = async function (
  url,
  token = undefined,
  uploadData = undefined,
  fetchMethod
) {
  try {
    const fetchPro = uploadData
      ? fetch(url, {
          method: fetchMethod,
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Content-Type': 'multipart/form-data', // DO NOT SET THIS WHEN FORMDATA IS USED AS THE BODY AS DJANGO PARSER WILL AUTOMATICALLY DETECT
          },
          body: uploadData,
        })
      : fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
    const data = await res.json(); //will return a status and uploaded data back
    if (!res.ok) throw new Error(`${data.message} (${res.status})`);
    return data;
  } catch (err) {
    throw err; //to throw the err to be handled within model.js instead of here
  }
};

// //API for pure JSON for various url/methods/body data, including token fetch, and with timeout of 's' seconds
// export const AJAXPAY = async function (
//   url,
//   token = undefined,
//   uploadData = undefined,
//   fetchMethod
// ) {
//   try {
//     const fetchPro = uploadData
//       ? fetch(url, {
//           method: fetchMethod,
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(uploadData),
//         })
//       : fetch(url, {
//           method: fetchMethod,
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json',
//           },
//         });

//     const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
//     const data = await res.json(); //will return a status and uploaded data back
//     if (!res.ok) throw new Error(`${data.message} (${res.status})`);
//     return data;
//   } catch (err) {
//     throw err; //to throw the err to be handled within model.js instead of here
//   }
// };
